<template>
  <div class="main-uniteApproved">
    <!--顶部-->
    <div class="top-search-add">
      <searchFrom @searchFromData="searchFromData"/>
    </div>
    <!--表格部分-->
    <div class="bottom-table">
      <el-table v-loading="loading" max-height="550" :data="tableData" border :header-cell-style="headerCellStyle">
        <template slot="empty">
          <IsEmpty />
        </template>
        <el-table-column type="index" label="序号" width="50" align="center" />
        <el-table-column prop="name" label="单位名称" align="center" show-overflow-tooltip />
        <el-table-column prop="type" label="单位类别" align="center" >
          <template slot-scope="scope">
            {{['', '企业', '政府'][scope.row.type]}}
          </template>
        </el-table-column>
        <el-table-column prop="characteristic" label="单位性质" align="center" >
          <template slot-scope="scope">
            {{ getContent(SIGNOPTIONS, scope.row.characteristic, 'label', 'text') }}
          </template>
        </el-table-column>
        <el-table-column prop="administrativeDivisionName" label="行政区划" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="contactPerson" label="单位联系人"  align="center"  />
        <el-table-column prop="contactPhone" label="联系电话"  align="center"  />
        <el-table-column prop="unitPhone" label="单位主账号"  align="center"  />
        <el-table-column prop="accountState" label="账号状态"  align="center" >
          <template slot-scope="scope">
            {{'审核不通过'}}
          </template>
        </el-table-column>
        <el-table-column prop="auditName" label="审核单位"  align="center"  />
        <el-table-column prop="note" label="审核备注"  align="center"  />
        <el-table-column prop="address6" label="操作"  width="230" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import searchFrom from "./searchForm"
  import { getContent } from '@/utils/index'
  import { SIGNOPTIONS } from '../dataDictionary'
  import { getSubordinateGovernmentPageList } from "@/api/unitManage"
  export default {
    name: "unitApproved",
    components: {
      searchFrom
    },
    data() {
      return {
        SIGNOPTIONS,
        examineId: '', //审核Id
        loading: false,
        examineVisible: false, //审核弹框
        total: 10,
        tableData: [],
        queryParams: { //查询参数
          pageNum: 1,
          pageSize: 10,
          status: 3
        }
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getContent,
      /*查询参数*/
      searchFromData(data) {
        this.queryParams = {...this.queryParams, ...data}
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleSizeChange(pageSize) {
        this.queryParams.pageSize = pageSize
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleCurrentChange(pageNum) {
        this.queryParams.pageNum = pageNum
        this.getList()
      },
      //查看
      toDetail(row) {
        console.log(row)
        this.$router.push({ path: '/baseSetting/unitInfoDetail', query: { id: row.id, type: row.type, tabs: 'third' } })
      },
      //  审核
      toExamine(row) {
        this.examineId = row.auditId
        this.examineVisible = true
      },
      //  查询列表
      async getList() {
        this.loading = true

        try{
          const res = await getSubordinateGovernmentPageList(this.queryParams)
          this.loading = false
          this.tableData = res.data.rows
          this.total = res.data.total
        }catch (e) {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main-uniteApproved {
    height: calc(100% - 70px);
    padding: 50px 40px;
    .top-search-add {
      overflow: hidden;
      button {
        float: right;
      }
    }
    .bottom-table {
      margin-top: 30px;
    }
  }
</style>
